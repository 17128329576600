"use client";
import { useCategoriesAPI } from "@/api/categories";
import { useContentAPI } from "@/api/contentIcons";
import {
  useIpAddressAPI,
  useLocationAPI,
  useNewProductAPI,
  usePopularProductAPI,
  useProductAPI,
} from "@/api/products";
import {
  ProductsCard,
  HeroBG,
  GiftCard,
  Promotions,
  ManageGiftCards,
  CategoriesCard,
  SupportedBlockchains,
  BlogCards,
} from "@/components/indexPageComponents";
import PromotionCategoriesCard from "@/components/indexPageComponents/PromotionCategoriesCard";
import { RootState } from "@/store";
import {
  giftCard,
  promotionsDetails,
  categoryCards,
  blockChainList,
  blogCard,
} from "@/utils/dummy";
import { Skeleton } from "primereact/skeleton";
import React from "react";
import { useSelector } from "react-redux";

const Page = () => {
  const location = useSelector((state: RootState) => state.location);

  const { data: IpAddress } = useIpAddressAPI();
  const { data: countryLocation }: any = useLocationAPI(IpAddress);

  const payload = `page=1&limit=${20}&countryOfAvailability=${
    location?.country || countryLocation?.country
  }&isActive=true`;

  const { data: productData, isLoading: loadingProduct }: any =
    useProductAPI(payload);
  const { data: newProductData, isLoading: loadingNewProduct }: any =
    useNewProductAPI(payload);
  const { data: popularProductData, isLoading: loadingPopularProduct }: any =
    usePopularProductAPI(payload, "popular");
  const { data: categoryData, isLoading: loadingCategory }: any =
    useCategoriesAPI(location.country);
  const { data: blockchainData, isLoading: loadingBlockchain }: any =
    useContentAPI();
  const products = productData;
  const categories = categoryData;
  const blockchain = blockchainData?.data?.data;

  const newProducts = newProductData;
  const popularProducts = popularProductData;
  return (
    <div className="indexPage">
      <HeroBG />
      {/* Popular/New products */}
      <div className="container mt-10">
        {loadingPopularProduct ? (
          <MultipleLoader />
        ) : (
          popularProducts?.data?.items?.length > 0 && (
            <ProductsCard
              imageList={popularProducts}
              products={popularProducts}
              location={location?.countryName || countryLocation?.countryName}
              slug={"Popular"}
              url={"popular"}
            />
          )
        )}

        {loadingNewProduct ? (
          <MultipleLoader />
        ) : (
          newProducts?.data?.items?.length > 0 && (
            <ProductsCard
              imageList={newProducts}
              products={newProducts}
              location={location?.countryName || countryLocation?.countryName}
              slug={"New"}
              url={"new"}
            />
          )
        )}
        {/* <GiftCard list={giftCard} />    */}
        {/* <Promotions list={promotionsDetails} />    */}
        {/* <ManageGiftCards   />    */}

        {categoryCards?.length > 0 && (
          <PromotionCategoriesCard list={categoryCards} />
        )}
        {blockchain?.length > 0 && (
          <SupportedBlockchains list={blockChainList} content={blockchain} />
        )}
        {/* <BlogCards list={blogCard} />    */}
      </div>
    </div>
  );
};

const MultipleLoader = () => {
  return (
    <>
      <div className="productContainer px-0">
        <div className="products">
          <ul className="productList py-4">
            {Array.from({ length: 20 }, (_, i) => (
              <li key={i} className="zoom link">
                <Skeleton width="15rem" height="6rem" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Page;
