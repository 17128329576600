"use client";
import React, { useRef, useEffect } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import CustomImage from "../CustomImage";
import Link from "next/link";

interface ImageItem {
  imageLink: string;
}

interface ProductsCardProps {
  imageList: ImageItem[];
  products: Array<any>;
  location: string;
  slug: string;
  url?: string;
}

const ProductsCard: React.FC<ProductsCardProps> = ({
  imageList,
  products,
  location,
  slug,
  url,
}) => {
  const productListRef = useRef<HTMLUListElement>(null);

  const scrollProductList = (distance: number) => {
    if (productListRef.current) {
      productListRef.current.scrollBy({ left: distance, behavior: "smooth" });
    }
  };

  return (
    <div className="productContainer px-0" style={{ overflow: "hidden" }}>
      <div className="header d-flex justify-content-between align-items-center">
        <h4>
          {slug} Products in <span className="location_text">{location}</span>
        </h4>
        <Link
          href={`/categories?type=${url}`}
          className="btn btn-primary shadow-sm px-4"
        >
          View All
        </Link>
      </div>
      <div className="products position-relative">
        {/* Left Button */}
        <button
          className="btn leftBtn d-none d-lg-block position-absolute start-0 top-50 translate-middle-y"
          onClick={() => scrollProductList(-200)}
        >
          <MdKeyboardArrowLeft />
        </button>

        {/* Product List */}
        <ul
          className="productList d-flex gap-3 py-4 px-2 m-0"
          ref={productListRef}
          style={{
            listStyle: "none",
            overflowX: "scroll",
            scrollBehavior: "smooth",
            padding: 0,
            margin: 0,
            whiteSpace: "nowrap",
          }}
        >
          {/* @ts-ignore data exists in products */}
          {products?.data?.items?.map((item: any, key: number) => (
            <li
              key={key}
              className="zoom link"
              style={{
                flex: "0 0 auto",
                width: "300px",
                boxShadow:
                  "0px 2px 4px rgba(0, 0, 0, 0.1), 0px -4px 4px rgba(0, 0, 0, 0.1), 2px 0px 4px rgba(0, 0, 0, 0.1), -2px 0px 4px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <Link
                href={`/products/${item?.id}`}
                style={{ textDecoration: "none", position: "relative" }}
              >
                <CustomImage
                  fullSrc={
                    item?.imageUrl ||
                    imageList[key]?.imageLink ||
                    "indexPage/debitCard.svg"
                  }
                  className={`productImage ${
                    item.isOutOfStock && "outOfStockCard_image"
                  }`}
                />
                {item.isOutOfStock && (
                  <div className="outOfStockBannerCategories">Out of Stock</div>
                )}
              </Link>
            </li>
          ))}
        </ul>

        {/* Right Button */}
        <button
          className="btn rightBtn d-none d-lg-block position-absolute end-0 top-50 translate-middle-y"
          onClick={() => scrollProductList(200)}
        >
          <MdKeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};

export default ProductsCard;
