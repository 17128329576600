import { baseAPI, baseURL } from ".";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";

interface MixPayResponse {
  // Define the structure of your response data here
  data: any;
}

export const confirmOrderAPI = async (orderId: any) => {
  try {
    const response = await baseAPI.put(`orders/confirm/${orderId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const verifyAccountAPI = async (
  accountId: string,
  productId: string
) => {
  try {
    const response = await baseAPI.post(`orders/verify-account`, {
      accountId,
      productId,
    });
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const cancelOrderAPI = async (orderId: any) => {
  try {
    const response = await baseAPI.put(`orders/cancel/${orderId}`);
    return response?.data;
  } catch (error) {
    return error;
  }
};
export const initializeOrderAPI = async (payload: any) => {
  try {
    const order = JSON.parse(localStorage.getItem("orderId") || "null");

    if (order?.id) {
      cancelOrderAPI(order.id).catch((error) => {
        console.error("Error canceling order:", error);
      });
    }
    const response = await baseAPI.post(`orders/initialize`, payload);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const sendReview = async (payload: any) => {
  try {
    const response = await baseAPI.post(`product-reviews`, payload);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const useFetchPaymentMethod = () => {
  return useQuery<MixPayResponse, Error>({
    queryKey: ["useFetchPaymentMethod"],
    queryFn: async () => {
      const response = await baseAPI.get(`payment/method`);
      return response?.data;
    },
  });
};

export const fetchPayeeId = async (id: string) => {
  try {
    const response = await baseAPI.get(
      `payment/method/${id}/selected-payment-method`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const generatePaymentLink = async (payload: any) => {
  try {
    const response = await baseAPI.post(
      `payment/method/generate-payment-link`,
      payload
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};
