import axios, { AxiosResponse } from "axios";
import { baseURL, baseAPI } from ".";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { CategoriesResponse, Category } from "@/types/mixpayTypes";

export const useCategoriesAPI = (countryCode?: string) => {
  return useQuery<any, Error>({
    queryKey: ["useCategoriesAPI", countryCode],
    queryFn: () =>
      baseAPI.get(`categories?countryOfAvailability=${countryCode}`),
    refetchInterval: false,
    enabled: !!countryCode,
    select: (response) => response?.data,
    staleTime: 1000 * 60 * 1,
  });
};
