import React from "react";
import Image from "next/image";
import connectionErrorImage from "../../public/images/connection-lost.png";

const ConnectionError = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        textAlign: "center",
      }}
    >
      <img
        src={connectionErrorImage.src}
        alt="Connection Error"
        style={{
          width: "50%",
        }}
      />
      <p style={{ marginTop: "1rem", fontSize: "1.2rem" }}>
        Connection lost. Please check your internet connection.
      </p>
    </div>
  );
};

export default ConnectionError;
