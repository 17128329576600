import { useIpAddressAPI, useLocationAPI, useProductAPI } from "@/api/products";
import { RootState } from "@/store";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

interface Product {
  imageUrl: string;
  name: string;
  id: string;
}

export default function Search() {
  const router = useRouter();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const location = useSelector((state: RootState) => state.location);

  const { data: IpAddress } = useIpAddressAPI();
  const { data: countryLocation } = useLocationAPI(IpAddress);

  const payload = `page=1&limit=32&countryOfAvailability=${
    location?.country || countryLocation?.country
  }&name=${searchTerm}&isActive=true`;

  const { data: productData, isLoading: loadingProduct } =
    useProductAPI(payload);

  useEffect(() => {
    if (productData?.data.items && searchTerm.trim() !== "") {
      const matches = productData?.data.items.slice(0, 4);
      setFilteredProducts(matches);
    } else {
      setFilteredProducts([]);
    }
  }, [productData, searchTerm]);

  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      router.push(`/categories?search=${encodeURIComponent(searchTerm)}`);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="d-block lr-2 position-relative">
      <Image
        loading={"lazy"}
        src="/images/indexPage/search.svg"
        width={18}
        height={18}
        alt="search"
        className="searchIcon"
        onClick={handleSearch}
        style={{ cursor: "pointer" }}
      />
      <input
        className="form-control search-input shadow-none"
        style={{
          height: 1000,
        }}
        type="search"
        placeholder="Search"
        aria-label="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {filteredProducts.length > 0 && (
        <div
          className="dropdown-menu show"
          style={{
            marginTop: 2,
            padding: 0,
          }}
        >
          {filteredProducts.map((product, index) => (
            <Link
              key={index}
              className="dropdown-item d-flex align-items-center"
              href={`/products/${product.id}`}
              style={{ cursor: "pointer" }}
            >
              <Image
                src={product.imageUrl}
                alt={product.name}
                width={20}
                height={20}
                className="me-2"
              />
              <span>{product.name}</span>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
