import axios, { AxiosResponse } from "axios";
import { baseAPI } from ".";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { useQuery } from "@tanstack/react-query";
import { IPResponse, LocationResponse } from "@/types/mixpayTypes";
import { useDispatch, useSelector } from "react-redux";
import { setLocation } from "@/store/location/locationSlice";
import { RootState } from "@/store";

export const useProductAPI = (payload: string | number | null) => {
  return useQuery<AxiosResponse<any>, Error>({
    queryKey: ["useProductAPI", payload],
    queryFn: () => baseAPI.get(`products?${payload}`),
    refetchInterval: false,
    enabled: !!payload,
    select: ({ data }) => data,
  });
};

export const useNewProductAPI = (payload: string | number | null) => {
  return useQuery<AxiosResponse<any>, Error>({
    queryKey: ["useNewProductAPI", payload],
    queryFn: () => baseAPI.get(`products/new?${payload}`),
    refetchInterval: false,
    enabled: !!payload,
    select: ({ data }) => data,
  });
};

export const usePopularProductAPI = (
  payload: string | number | null,
  type: string | null
) => {
  return useQuery<AxiosResponse<any>, Error>({
    queryKey: ["usePopularProductAPI", payload],
    queryFn: () => baseAPI.get(`products/${type}?${payload}`),
    refetchInterval: false,
    enabled: !!payload,
    select: ({ data }) => data,
  });
};

export const currenCyConverter = createAsyncThunk(
  "product/getProducts",
  async (payload: any) => {
    const response = await baseAPI.get(`products?${payload ?? ""}`);
    return response?.data;
  }
);

export const getCategories = async () => {
  try {
    const response = await baseAPI.get("products");
    return response?.data;
  } catch (error) {
    throw error; // Throw the error instead of returning it
  }
};

export const useSingleProductAPI = (id: string | number | null) => {
  return useQuery<AxiosResponse<any>, Error>({
    queryKey: ["useSingleProductAPI", id],
    queryFn: () => baseAPI.get(`products/${id}/info`),
    refetchInterval: false,
    enabled: !!id,
  });
};

export const useProductItemsAPI = (id: string | number | null) => {
  return useQuery<AxiosResponse<any>, Error>({
    queryKey: ["useProductItemsAPI", id],
    queryFn: () => baseAPI.get(`products/items/${id}`),
    refetchInterval: false,
    enabled: !!id,
  });
};

export const useIpAddressAPI = () => {
  return useQuery<AxiosResponse<IPResponse>, Error>({
    queryKey: ["useIpAddressAPI"],
    queryFn: () => axios.get(`https://api.ipify.org?format=json`),
    refetchInterval: false,
    select: (response) => response?.data?.ip,
  });
};

export const useLocationAPI = (ipAddress: any) => {
  const isManualChange = localStorage.getItem("isManualChange") === "true";
  const lastIpLocation = JSON.parse(localStorage.getItem("ipLocation") as any);
  const location = useSelector((state: RootState) => state.location);
  const dispatch = useDispatch();

  return useQuery<LocationResponse, Error>({
    queryKey: ["useLocationAPI", ipAddress],
    queryFn: async (): Promise<LocationResponse> => {
      // if (!isManualChange) {
      //   dispatch(setLocation({ country: response.data.data.country, countryName: response.data.data.countryName }));
      // }
      if (lastIpLocation?.ipAddress !== ipAddress) {
        const response: AxiosResponse<{ data: LocationResponse }> =
          await baseAPI.get(`location?ipAddress=${ipAddress}`);
        const newIpLocation = {
          ipAddress,
          country: response.data.data.country,
          countryName: response.data.data.countryName,
        };
        localStorage.setItem("ipLocation", JSON.stringify(newIpLocation));
        dispatch(
          setLocation({
            country: response.data.data.country,
            countryName: response.data.data.countryName,
          })
        );

        return response.data.data;
      } else {
        return lastIpLocation;
      }
    },
    refetchInterval: false,
    enabled: !!ipAddress,
  });
};
