import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LocationState {
  country: string;
  countryName: string;
}

interface PayloadAction {
  payload: {
    country: string;
    countryName: string;
  };
}

const loadLocationState = (): LocationState | null => {
  if (typeof window === "undefined") {
    return null;
  }
  try {
    const serializedState = localStorage.getItem("location");
    if (serializedState === null) {
      return null;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn("Could not load location state from localStorage", e);
    return null;
  }
};

const saveLocationState = (state: LocationState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("location", serializedState);
  } catch (e) {
    console.warn("Could not save location state to localStorage", e);
  }
};

const initialState: LocationState = loadLocationState() || {
  country: "",
  countryName: "",
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation: (state: LocationState, action: PayloadAction) => {
      state.country = action.payload.country;
      state.countryName = action.payload.countryName;
      saveLocationState(state);
    },
    clearLocation: (state: LocationState) => {
      state.country = "";
      state.countryName = "";
      localStorage.removeItem("location");
    },
  },
});

export const { setLocation, clearLocation } = locationSlice.actions;
export default locationSlice.reducer;
