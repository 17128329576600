import axios, { AxiosResponse } from "axios";
import { baseURL, baseAPI } from ".";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { CategoriesResponse, Category } from "@/types/mixpayTypes";

export const useContentAPI = () => {
  return useQuery<any, Error>({
    queryKey: ["useContentAPI"],
    queryFn: () => baseAPI.get("cms/icons"),
    refetchInterval: false,
    select: (response) => response?.data,
  });
};
